import './src/styles/_index.scss'

const scrollTo = id => () => {
  const el = document.querySelector(id)
  if (el) return window.scrollTo(0, el.offsetTop - 20)
  return false
}

export const onRouteUpdate = ({ location: { hash } }) => {
  if (typeof window !== `undefined`) {
    history.scrollRestoration = 'manual'
  }
  window.scrollTo(0, 0)

  if (hash) {
    window.setTimeout(scrollTo(hash), 10)
  }
}

export const onInitialClientRender = () => {
  window.scrollTo(0, 0)
}

export const shouldUpdateScroll = () => {
  return false
}
